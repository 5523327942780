import Web3 from "web3";
import { toast } from "react-toastify";
import config from "../../lib/config";
import WalletConnectProvider from "@walletconnect/web3-provider"
import {toastAlert} from '../../helper/toastAlert';
toast.configure();
let toasterOption = config.toasterOption;


export async function getprovider() {
  if (
    localStorage.getItem("walltype") &&
    localStorage.getItem("walltype") != null &&
    localStorage.getItem("walltype") != undefined &&
    localStorage.getItem("walltype") != "" &&
    localStorage.getItem("walltype") == "trust"
  ) {
    var provider = new WalletConnectProvider({
      rpc: {
        [config.livechainid]: config.liverpcUrls,
      },
      chainId: config.livechainid,
    });

    provider.on("connect", () => { });

    await provider.enable();
    let web3 = new Web3(provider);
    var network = await web3.eth.net.getId();
    var result = await web3.eth.getAccounts();
    if (config.livechainid == network) {
      var data = {
        provider: provider,
        address: web3.utils.toChecksumAddress(result[0]),
      };
      return data;
    } else {
      // await provider.disconnect();
      let result = await web3.eth.getAccounts();
      let data = {
        provider: window.web3.currentProvider,
        address: web3.utils.toChecksumAddress(result[0]),
      }
      return data;
    }
  } else {
    if (window.ethereum) {
      console.log('ethereum')
      // console.log(process.env.NODE_ENV,"env")
      console.log(config.networkVersion)
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          // await window.ethereum.enable()
          const web3 = new Web3(window.web3.currentProvider);
          console.log(await window.web3.currentProvider.isMetaMask, "ismetamask")
          // if (window.web3.currentProvider.isMetaMask === true) {
            if (window.web3) {
              console.log("true")
              const isLocked = !(await window.ethereum._metamask.isUnlocked());
            if (isLocked) {
              var data = {
                address: "0x0000000000000000000000000000000000000001", //this default address for read contract
                provider: config.liverpcUrls
              }
              return data
            }
              console.log(window.web3.currentProvider.networkVersion, config.networkVersion)
              if (window.web3.currentProvider.networkVersion == config.networkVersion) {
                console.log("account")
                var result = await web3.eth.getAccounts();
                //  web3.eth.getAccounts();
                console.log("result1234", result[0]);
                var data = {
                  provider: window.web3.currentProvider,
                  address: web3.utils.toChecksumAddress(result[0]),
                };
                console.log(data, 'data dfdsfsfdsf')
                return data;
              } else {
                var result = await web3.eth.getAccounts();
                let data = {
                  provider: window.web3.currentProvider,
                  address: web3.utils.toChecksumAddress(result[0]),
                }
                toastAlert('warn', "Please connect binance mainnet", "wallet");

                return data;
              }
            }
          // }
        }
      } catch (err) {
        console.log(err, 'erere 12313')
      }
    } else {
      let web3 = new Web3(config.liverpcUrls);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      var data = {
        address: "0x0000000000000000000000000000000000000001", //this default address for read contract
        provider: config.liverpcUrls
      }
      return data
    }
  }
}


try {
  window.addEventListener("load", (event) => {
    event.preventDefault();
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async function (accounts) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        window.location.reload(true);
        localStorage.setItem('account', accounts[0]);
      });

      window.ethereum.on("networkChanged", async function (networkId) {
        if (networkId == config.networkVersion) {
          window.location.reload(true);
          // getprovider();
        } else {
          toast.warn("Please connect binance Mainnet",toasterOption);
          try {
            let web3 = new Web3(window.web3.currentProvider);
            await web3.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: '0x38' }],
            });
            let address = await web3.eth.getAccounts();
            console.log(address[0], 'address');
            localStorage.setItem('account', address[0]);
            toast.success( "Wallet connected 🦊 ", toasterOption);
            window.location.reload(false);
          } catch (switchError) {
            console.log("some error on switching", switchError);
          }
        }
      });
    }
  });
} catch (e) {
  console.log("error on wallet watch function")
}
