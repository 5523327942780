// let NODE_ENV = "local";
let NODE_ENV      = "demo";              
let Front_URL     = "";
let Back_URL      = "";
let v1Url         = "";
let limit         = "12";
let limitMax      = "3000";
let decimalvalues = 1000000000000000000;
let fee           = 2500000000000000000;
let toFixed       = 6;
var IPFS_IMG      = "https://ipfs.io/ipfs";
let toasterOption = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

let networkVersion     = "97";  //mainnet -56,testnet-97
let currencySymbol     = "BNB";
let tokenSymbol        = "SBNFT";
let LotterytokenSymbol = "Spidey Barder";
let liverpcUrls = "https://bsc-dataseed1.binance.org:443/";
let livechainid = 56;
// let currencySymbol = 'BNB';
// let tokenSymbol = 'DETH';
const ownerAddr        = "0x3778EcF0b729ecd4DdcA96e2D9f588314Ad73F6D";//0x26F9F0c809779feA862B28a106Fd883fac4a3Fb7
const adminAddress     = "0x3778EcF0b729ecd4DdcA96e2D9f588314Ad73F6D"; //0xe51726341cEbD915394A9C3E11CCaF5C63aae51B
const DETHtokenAddr    = "0xA6221fd54623e163450bDc35ACc1De9D179C59B1";
const Spideybarder     = "0xA6221fd54623e163450bDc35ACc1De9D179C59B1";
const singleContract   = "0xf3dF953a34B1E8259566f08574a5bB619efc2dC4";
const multipleContract = "0x0c6806cf55107668489C7cFCA1b3A1B0175A3Dc7";
const exchangeAddress  = "0xf3dF953a34B1E8259566f08574a5bB619efc2dC4";
const lotteryAddr      = "0x581A1C7BE1e2D0C77905Bdf77B2EB0f067B19f1f";

// live;
// const ownerAddr = "0x4Dd44dEa04651BE75a53Cb77fd316987fbD1d12b";
// const adminAddress = "0x4Dd44dEa04651BE75a53Cb77fd316987fbD1d12b";
// const singleContract = "0xf80C35C2d4951d635B029bf146Be06cc05265E46";
// const multipleContract = "0x5f6d76734bE80c5855F03a21E6c609fb6491ED32";
// const DETHtokenAddr = "0xE427bb1d14553f6DE5FE64466cdf69669572A54b";
// const Spideybarder = "0xE427bb1d14553f6DE5FE64466cdf69669572A54b";
// const exchangeAddress = "0xf80C35C2d4951d635B029bf146Be06cc05265E46";

 const DETHtokenAddrlott = "0xA6221fd54623e163450bDc35ACc1De9D179C59B1";
const Spideybarderlott = "0xA6221fd54623e163450bDc35ACc1De9D179C59B1"; //0x6c9C37A99B4cf061A9c6557aC2310B2FA2bF6524

const ipfsurl          = "https://ipfs.io/ipfs";
var tokenAddr          = {
  DETH                  : '0xA6221fd54623e163450bDc35ACc1De9D179C59B1',
   Spideybarder         : "0xA6221fd54623e163450bDc35ACc1De9D179C59B1",
};
const singleType       = 721;
const multipleType     = 1155;

if (NODE_ENV === "production") {
  Front_URL        = "https://spideybarter.com/SpideyBarter-NFT/";
  Back_URL         = "https://api.spideybarter.com";
  v1Url            = "https://api.spideybarter.com/v1";
} else if (NODE_ENV === "demo") {
  Front_URL        = "https://spidey-nft-frontend.pages.dev/";
  Back_URL         = "https://spideynftapi.wealwin.com/";
  v1Url            = "https://spideynftapi.wealwin.com/v1";
  IPFS_IMG         = "https://ipfs.infura.io/ipfs";
} else {
  Front_URL        = "http://localhost:3000";
  Back_URL = 'http://localhost:2056';
  v1Url = "http://localhost:2056/v1";
  // Back_URL         = "http://spideybarter.wealwin.com:3001";
  // v1Url            = "http://spideybarter.wealwin.com:3001/v1";
  // Back_URL         = "https://api.spideybarter.com";
  // v1Url            = "https://api.spideybarter.com/v1";
  IPFS_IMG         = "https://ipfs.infura.io/ipfs";
}

let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  v1Url: v1Url,
  vUrl: v1Url,
  ipfsurl: ipfsurl,
  fee: fee,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  networkVersion: networkVersion,
  currencySymbol: currencySymbol,
  LotterytokenSymbol: LotterytokenSymbol,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  exchangeAddress: exchangeAddress,
  singleContract: singleContract,
  multipleContract: multipleContract,
  lotteryAddr: lotteryAddr,
  adminAddress: adminAddress,
  DETHtokenAddr: DETHtokenAddr,
  Spideybarder: Spideybarder,
  ownerAddr: ownerAddr,
  singleType: singleType,
  multipleType: multipleType,
  tokenAddr: tokenAddr,
  tokenSymbol: tokenSymbol,
  IPFS_IMG: IPFS_IMG,
  livechainid:livechainid,
  liverpcUrls:liverpcUrls,
  Spideybarderlott: Spideybarderlott,
};

export default key;
