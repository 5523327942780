import React, {
  useRef,
  useEffect,
  useState
} from "react";
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";

import isEmpty from "../lib/isEmpty";

import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { withStyles } from "@material-ui/core/styles";
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import SidebarLinksAfterlogin from "components/Header/SidebarLinksAfterlogin.js";
import HeaderDashboard from "components/Header/HeaderDashboard";
import WalletModal from "components/WalletModal.js";
import Countdown, { zeroPad } from "react-countdown";

import Web3 from 'web3';
import moment from 'moment';

import config from '../lib/config';
import DETH_ABI from '../ABI/DETH_ABI.json';
import EXCHANGE_ABI from '../ABI/EXCHANGE.json';


import {
  getCurAddr,
  halfAddrShow
} from '../actions/v1/User';

import {
  TokenCounts_Get_Detail_Action,
  BidApply_ApproveAction,
  acceptBId_Action,
  Bidding_Detail_Action
} from '../actions/v1/token';

import ConnectWallet from './separate/Connect-Wallet';
import { WalletRef } from './separate/WalletRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { PurchaseNowRef } from "./separate/PurchaseNowRef";
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { LikeRef } from './separate/LikeRef';
import { CancelOrderRef } from './separate/CancelOrderRef';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const exchangeAddress = config.exchangeAddress;
const dashboardRoutes = [];
const IPFS_IMGurl = config.ipfsurl;

const drawerWidth = 240;

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 7.5 + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing.unit,
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  grow: {
    flexGrow: 1
  }
});

const Info = (props) => {
  const [open, setOpen] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)



  const handleDrawerOpen = () => {
    console.log("data---------------")
    setOpen(!open)
  };

  const handleDrawerClose = () => {
    setOpen(true)
  };

  const handleMenu = event => {
    console.log("----event", event)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  var LikeForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();

  async function BidApply_ApproveCall() {
    PlaceABidForwardRef.current.BidApply_ApproveCall();
  }
  async function BidApply_SignCall() {
    PlaceABidForwardRef.current.BidApply_ApproveCall();
  }

  var { tokenidval } = useParams();

  // wallet related : common state
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [tokenCounts, Set_tokenCounts] = useState(tokenidval);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  // const [MultipleWei, Set_MultipleWei] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [HitItem, Set_HitItem] = useState({});
  const [BuyOwnerDetail, Set_BuyOwnerDetail] = useState({});
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [totaluserbidAmt, setTotaluserbidAmt] = React.useState(0);
  const [onwer_price, set_owner_price] = useState({})

  const AfterWalletConnected = async () => {
    var curAddr = await getCurAddr();
    var payload = {
      curAddr: curAddr,
      tokenCounts: tokenidval
    };
    TokenCounts_Get_Detail_Call(payload);
    BiddingAmout();

    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.Spideybarder);
      var tokenBal = await CoursetroContract.methods.balanceOf(curAddr).call();
      var tokenBalance = tokenBal / config.decimalvalues;
      Set_TokenBalance(tokenBalance.toFixed(5));
    }
    try{
      LikeForwardRef.current.getLikesData();
    }catch(err){
      
    }
    
  }
  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = await getCurAddr();
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {

      var TokenResp = Resp.data.Detail.Resp;
      console.log("item_result",TokenResp)
      if(
        TokenResp
        && TokenResp.Token
        && TokenResp.Token[0]
        && TokenResp.Token[0].tokenowners_current
      ) {

        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          console.log("element11:",element)
          if(element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner != curAddr) {
    
            Set_BuyOwnerDetailFirst(element);
            set_owner_price(element)
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);
      if(TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }
      var IndexVal = -1;
      if(TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => val.tokenOwner.toString() == curAddr.toString());
      }
      var newMyTokenBalance = 0;
      if(IndexVal > -1) {
        newMyTokenBalance = tokenowners_all[IndexVal].balance
        Set_MyTokenBalance(newMyTokenBalance);
        console.log("balance:",tokenowners_all[IndexVal])
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
      }
      else {
        newMyTokenBalance = 0;
        Set_MyTokenBalance(0);
        Set_MyTokenDetail({});
      }

      if(TokenResp.TotalQuantity) {
        Set_AllowedQuantity(TokenResp.TotalQuantity - newMyTokenBalance);
      }
      else {
        Set_AllowedQuantity(0);
      }
      if(TokenResp.Token && TokenResp.Token[0]) {
        console.log("list_item:",TokenResp.Token[0])
        Set_item(TokenResp.Token[0]);
      } 
    }
  }

  // const TokenCounts_Get_Detail_Call = async (payload) => {

  //   // console.log('------test halfAddrShow : ', halfAddrShow('ddddddddddddd'));
  //   var curAddr = await getCurAddr();
  //   var Resp = await TokenCounts_Get_Detail_Action(payload);
  //   if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {

  //     var TokenResp = Resp.data.Detail.Resp;
  //     if (
  //       TokenResp
  //       && TokenResp.Token
  //       && TokenResp.Token[0]
  //       && TokenResp.Token[0].tokenowners_current
  //     ) {

  //       for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {

  //         const element = TokenResp.Token[0].tokenowners_current[i];
  //         set_owner_price(element)
  //         if (element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner != curAddr) {
  //           Set_BuyOwnerDetailFirst(element);
  //           break;
  //         }
  //       }


  //     }
  //     Set_tokenCounts_Detail(TokenResp);

  //     if (TokenResp.Bids) {
  //       Set_Bids(TokenResp.Bids);
  //     }

  //     var IndexVal = -1;

  //     if (TokenResp && TokenResp.Token && TokenResp.Token[0] && TokenResp.Token[0].tokenowners_all && curAddr) {
  //       var tokenowners_all = TokenResp.Token[0].tokenowners_all;
  //       IndexVal = tokenowners_all.findIndex(val => val.tokenOwner.toString() == curAddr.toString());
  //     }

  //     var newMyTokenBalance = 0;

  //     if (IndexVal > -1) {
  //       newMyTokenBalance = tokenowners_all[IndexVal].balance
  //       Set_MyTokenBalance(newMyTokenBalance);
  //       Set_MyTokenDetail(tokenowners_all[IndexVal]);
  //     }
  //     else {
  //       newMyTokenBalance = 0;
  //       Set_MyTokenBalance(0);
  //       Set_MyTokenDetail({});
  //     }

  //     if (TokenResp.TotalQuantity) {
  //       Set_AllowedQuantity(TokenResp.TotalQuantity - newMyTokenBalance);
  //     }
  //     else {
  //       Set_AllowedQuantity(0);
  //     }
  //     if (TokenResp.Token && TokenResp.Token[0]) {
  //       console.log("list_item:", TokenResp.Token[0])
  //       Set_item(TokenResp.Token[0]);
  //     }
  //   }
  // }
  async function BiddingAmout() {
    try {
      var currAddr = window.web3.eth.defaultAccount;
      var payload = { currAddr }
      var Resp = await Bidding_Detail_Action(payload);
      if (Resp && Resp.data) {
        setTotaluserbidAmt(Resp.data.res1)
      }
    } catch (err) {
    }
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  // Countdown Timer
  const currentDate = new Date();
  var currDate = new Date();
  var startdate = new Date(item.clocktime);
  var enddate = new Date(item.endclocktime);
  var auction = "false";
  var finish = "";
  var enddate1 = "";
  var showlist = "true";
  if (item.type==721&&item.PutOnSaleType=="TimedAuction") {
      auction = "true";
      var a = moment(item.clocktime);
      var b = moment(item.endclocktime);
      var c = moment();
      a.diff(b); // 86400000
      var diffInMs = a.diff(c);
      finish = b.diff(c);
      enddate1 = parseFloat(diffInMs);
     if (finish > 0) {
      showlist = "true";
      } else {
      var auctionTxt = "Ended";
      showlist = "false";
      }
  }
  
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">d</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">h</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">m</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">s</span></span>
      </div>
    );
  };

  const { classes, theme } = props;
  // const open = Boolean(anchorEl);    
  return (
    <div className={classes.root}>

    <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_TokenBalance = {Set_TokenBalance}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        TokenBalance = {TokenBalance}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />

      <ScrollToTopOnMount />
      <CssBaseline />

      {/* Header APP */}
      <HeaderDashboard
        classes={classes}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleMenu={handleMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />

      {/*  side Menu */}
      <SidebarLinksAfterlogin classes1={classes} open={open} />
      <div className="overlay"></div>
      <main className={classes.content + " dashContent info_dashcontent"}>
        <div className={classes.toolbar} />
        <div className="dashPages">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div>
                <div className="row info_row mx-0 buyer_div">
                  <div className="col-12 col-md-8" id="image_div">
                    <div class="d-flex align-items-center">
                      <Link class="goback_link" to="/nft">
                        <i class="bi bi-chevron-left"></i><span>Go back</span>
                      </Link>
                    </div>

                    <div className="flex_center py-5">
                      <div className="clearfix"></div>
                      {
                      item && item.image && item.image.split('.').pop() == 'mp4' ?
                      <video src={`${IPFS_IMGurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid img_info" controls />
                      :
                      item && item.image && item.image.split('.').pop() == "mp3" ?
                      <div className="music_img_info_div">
                      <img src={`${config.Back_URL}/images/music.png`} alt="Collections" className="img-fluid img_info music_img_info " />
                      <audio src={`${IPFS_IMGurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio" autostart="off">
                      </audio>
                      </div>
                      :
                      <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid img_info" />
                      }
                      <div className="img_des" id="img_des">
                        <p className="info_title work_brk_text">{item.tokenName}</p>
                        <h3 className="info_h3">by<span className="px-2 work_brk_text">{isEmpty(item.usersinfo) ? item.tokenOwner : item.usersinfo.name}</span>on<span className="pl-2">NFT</span></h3>
                      </div>
                    </div>

                  </div>
                  <div className="col-12 col-md-4 bg_pink" id="detai_div">
                    <div className="">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <span className="info_title">{item && item.tokenName ? item.tokenName : ""}</span>
                        </div>
                        <div className="d-flex">
                          <div class="masonry_likes">
                          {
                           (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.tokenCounts)) > -1)
                           ?(<i className="fas fa-heart mr-2 liked" onClick={() => LikeForwardRef.current.hitLike(item)} style={{cursor:'pointer'}}></i>)
                           :(<i className="far fa-heart mr-2" onClick={() => LikeForwardRef.current.hitLike(item)} style={{cursor:'pointer'}}></i>)
                           }
                           <span class={item.tokenCounts+'-likecount mr-2'}>{item.likecount}</span>
                          
                          </div>
                          {/*<div class="dropdown">
                            <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                            </div>
                          </div>*/}
                        </div>
                      </div>
                      <h3 className="info_h3">
                      {
                        item
                        && item.tokenBid == true
                        && item.PutOnSaleType != "TimedAuction"
                        ?
                        (onwer_price!==undefined&&
                        (
                        onwer_price.tokenPrice!=undefined
                        && onwer_price.tokenPrice != null&&onwer_price.type==721
                        &&onwer_price.tokenPrice!=0 ?
                        onwer_price.tokenPrice + ' ' + config.currencySymbol
                        : item&&item.PutOnSaleType=="FixedPrice"&&item.tokenowners_current[0].tokenPrice?
                        item.tokenowners_current[0].tokenPrice+''+config.currencySymbol
                        :        
                      'Not for Sale'
                        ))
                        :item&&item.PutOnSaleType=="FixedPrice"&&item.tokenowners_current[0].tokenPrice
                        ?
                        item.tokenowners_current[0].tokenPrice+''+config.currencySymbol
                        :
                        item.minimumBid + ' ' +config.tokenSymbol
                    }
                            {/* {
                            item
                            && item.tokenBid == true
                            && item.clocktime == null
                            && item.endclocktime == null
                            ?

                            (onwer_price!==undefined&&
                            (
                            onwer_price.tokenPrice!==undefined
                            && onwer_price.tokenPrice != null
                            &&onwer_price.tokenPrice!=0 ?
                            onwer_price.tokenPrice + ' ' + config.currencySymbol : 'Not for Sale'

                            ))
                            :

                            item && item.minimumBid? item.minimumBid + ' ' +config.tokenSymbol: '0 '+config.tokenSymbol
                            } */}
                        </h3>
                      <p className="info_des">{item.tokenDesc}</p>
                      {/* <div className="countdown_panel">
                        <div className="countdown_panel_div countdown_panel_div_info">
                          <Countdown date={`${year}-09-15T12:00:00`} renderer={renderer} />
                          <span className="left_text">Left</span>
                        </div>
                      </div> */}
                      <nav className="masonry_tab_nav mt-4 info_tab">
                        <div className="nav nav-tabs masonry_tab" id="nav-tab" role="tablist">
                          {/* <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">Info</a> */}
                          <a className="nav-link active" id="owners-tab" data-toggle="tab" href="#owners" role="tab" aria-controls="active" aria-selected="false">Owners</a>
                          {/* <a className="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false">History</a> */}
                          <a className="nav-link" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="false">Details</a>
                          <a className="nav-link" id="bid-tab" data-toggle="tab" href="#bid" role="tab" aria-controls="bid" aria-selected="false">Bid</a>

                        </div>
                      </nav>
                      <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                        <div className="tab-pane fade show" id="info" role="tabpanel" aria-labelledby="info-tab">
                          <div className="proposal_panel_overall">
                            <div className="inner_div_info">
                              <div className="media follow_media info_media">
                                <img src={require("../assets/images/follower_1.png")} alt="User" className="img-fluid mr-3" />
                                <div className="media-body flex_body">
                                  <div>
                                    <p className="mt-0 media_num">Owner</p>
                                    <p className="mt-0 media_text  mb-0">Aqueento</p>

                                  </div>

                                </div>
                              </div>
                              <div className="media follow_media info_media">
                                <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                                <div className="media-body flex_body info_flex_body">
                                  <div>
                                    <p className="mt-0 media_num">Creator</p>
                                    <p className="mt-0 media_text  mb-0">Garybaldi</p>

                                  </div>
                                  <div className="ml-2 ml-cus">
                                    <div className="card owner_card my-0">
                                      <div className="card-body p-2">
                                        <div className="flex_txt">
                                          <div className="prce_red">10%</div>
                                          <div className="prce_grey">
                                            <p className="mb-0 price_1">
                                              Sales to
                                              </p>
                                            <p className="mb-0 price_2">
                                              the creator
                                              </p>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="media follow_media info_media">
                                <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid mr-3" />
                                <div className="media-body flex_body">
                                  <div>
                                    <p className="mt-0 media_num">Collection</p>
                                    <p className="mt-0 media_text  mb-0">CRYPTOxPINS</p>

                                  </div>

                                </div>
                              </div>
                              <div className="media follow_media info_media">
                                <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid mr-3" />
                                <div className="media-body flex_body">
                                  <div>
                                    <p className="mt-0 media_num">Collection</p>
                                    <p className="mt-0 media_text  mb-0">CRYPTOxPINS</p>

                                  </div>

                                </div>
                              </div>
                            </div>
                            <div className="card info_big_card mb-0">
                              <div className="card-body">
                                <div className="media follow_media">
                                  <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid mr-3" />
                                  <div className="media-body flex_body">
                                    <div>
                                      <p className="mt-0 media_text_big_1">Highest bid by <span className="text_blk">yeah66</span></p>
                                      <p className="mt-0 mb-0 media_text_big_2"><span className="text_blk">1.01 BNB</span> $410.86</p>
                                    </div>


                                  </div>
                                </div>
                                <div className="mt-3 text-center">
                                  {/* <Button className="create_btn mb-2">Buy Now</Button> */}
                                  <Button className="btn_outline_red mb-2" data-toggle="modal" data-target="#buy_modal">Buy Now</Button>
                                  {/* <Button className="btn_outline_red ml-2 mb-2">Bid</Button> */}
                                  <Button className="btn_outline_red ml-2 mb-2" data-toggle="modal" data-target="#place_bid_modal">Bid</Button>

                                </div>
                                <p className="mt-0 media_text_big_1 text-center">Service fee 2%, 0.923 BEP $1,922.36</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade show active" id="owners" role="tabpanel" aria-labelledby="owners-tab">
                  <div className="proposal_panel_overall">
                    <div className="inner_div_info">
                      {
                      item.tokenowners_current && item.tokenowners_current.length > 0 && item.tokenowners_current.map((itemCur, i) => {
                      return <div className="media follow_media info_media">
                        <img src={
                        (item.tokenOwnerInfo.image[i] != ''
                        ? `${config.Back_URL}/images/${item.tokenOwnerInfo._id}/${item.tokenOwnerInfo.image[i]}`
                        : `${config.Back_URL}/images/noimage.png`)
                        } alt="Owner" className="img-fluid mr-3" width="60px" height="60px" />
                        <div className="media-body flex_body">
                          <div>
                            <p className="mt-0 media_num">
                              {
                              (item.tokenOwnerInfo.name[i] != ''
                              ? item.tokenOwnerInfo.name[i]
                              : halfAddrShow(itemCur.tokenOwner))
                              }
                            </p>
                            { itemCur.tokenPrice > 0 && 
                              <p className="mt-0 media_text mb-0">
                              {/* {itemCur.balance}/{itemCur.quantity} */}
                              on sale for {itemCur.tokenPrice} {config.currencySymbol} {itemCur.quantity > 0 && 'each'}
                               </p>
                            }
                            { itemCur.tokenPrice == 0 && 
                            <p className="mt-0 media_text mb-0">
                              {/* {itemCur.balance}/{itemCur.quantity} */}
                               Not for sale</p>}
                            {
                            itemCur.tokenPrice > 0 && itemCur.balance > 0 && itemCur.tokenOwner != UserAccountAddr && 
                            <Button className="btn_outline_red mb-2" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item,itemCur)} >Buy Now</Button>
                            }
                          </div>
                        </div>
                      </div>
                      })
                      }
                    </div>
                  </div>
                </div>
                        <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                          <div className="proposal_panel_overall">
                            <div className="inner_div_info">
                              <div className="media follow_media info_media">
                                <img src={require("../assets/images/follower_1.png")} alt="User" className="img-fluid mr-3" />
                                <div className="media-body flex_body">
                                  <div>
                                    <p className="mt-0 media_num">Owner</p>
                                    <p className="mt-0 media_text  mb-0">Aqueento</p>

                                  </div>

                                </div>
                              </div>
                              <div className="media follow_media info_media">
                                <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                                <div className="media-body flex_body info_flex_body">
                                  <div>
                                    <p className="mt-0 media_num">Creator</p>
                                    <p className="mt-0 media_text  mb-0">Garybaldi</p>

                                  </div>
                                  <div className="ml-2 ml-cus">
                                    <div className="card owner_card my-0">
                                      <div className="card-body p-2">
                                        <div className="flex_txt">
                                          <div className="prce_red">10%</div>
                                          <div className="prce_grey">
                                            <p className="mb-0 price_1">
                                              Sales to
                                              </p>
                                            <p className="mb-0 price_2">
                                              the creator
                                              </p>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="media follow_media info_media">
                                <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid mr-3" />
                                <div className="media-body flex_body">
                                  <div>
                                    <p className="mt-0 media_num">Collection</p>
                                    <p className="mt-0 media_text  mb-0">CRYPTOxPINS</p>

                                  </div>

                                </div>
                              </div>
                              <div className="media follow_media info_media">
                                <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid mr-3" />
                                <div className="media-body flex_body">
                                  <div>
                                    <p className="mt-0 media_num">Collection</p>
                                    <p className="mt-0 media_text  mb-0">CRYPTOxPINS</p>

                                  </div>

                                </div>
                              </div>
                            </div>
                            <div className="card info_big_card mb-0">
                              <div className="card-body">
                                <div className="media follow_media">
                                  <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid mr-3" />
                                  <div className="media-body flex_body">
                                    <div>
                                      <p className="mt-0 media_text_big_1">Highest bid by <span className="text_blk">yeah66</span></p>
                                      <p className="mt-0 mb-0 media_text_big_2"><span className="text_blk">1.01 BNB</span> $410.86</p>
                                    </div>


                                  </div>
                                </div>
                                <div className="mt-3 text-center">
                                  {/* <Button className="create_btn mb-2">Buy Now</Button> */}
                                  <Button className="btn_outline_red mb-2" data-toggle="modal" data-target="#buy_modal">Buy Now</Button>
                                  <Button className="btn_outline_red ml-2 mb-2" data-toggle="modal" data-target="#place_bid_modal">Bid</Button>

                                  {/* <Button className="btn_outline_red ml-2 mb-2">Bid</Button> */}
                                </div>
                                <p className="mt-0 media_text_big_1 text-center">Service fee 2%, 0.923 BEP $1,922.36</p>
                              </div>
                            </div>
                          </div>
                        </div>

                            <div className="tab-pane fade" id="details" role="tabpanel" aria-labelledby="details-tab">
                      <div className="proposal_panel_overall">
                        <div className="inner_div_info">
                          {
                          UserAccountAddr
                          && item
                          && item.tokenowners_current
                          && item.tokenowners_current.findIndex(e => e.tokenOwner == UserAccountAddr) > -1
                          && item.unlockcontent != '' &&
                          <div className="media follow_media info_media">
                            <img src={require("../assets/images/follower_1.png")} alt="User" className="img-fluid mr-3" />
                            <div className="media-body flex_body">
                              <div>
                                <p className="mt-0 media_num">Unlockable</p>
                                <p className="mt-0 media_text mb-0">{item.unlockcontent}</p>
                              </div>
                            </div>
                          </div>}
                          <div className="media follow_media info_media">
                            <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                            <div className="media-body flex_body info_flex_body">
                              <div>
                                <p className="mt-0 media_num">Creator</p>
                                <p className="mt-0 media_text mb-0">{halfAddrShow(item.tokenCreator)}</p>
                              </div>
                              <div className="ml-2 ml-cus">
                                <div className="card owner_card my-0">
                                  <div className="card-body p-2">
                                    <div className="flex_txt">
                                      <div className="prce_red">{item.tokenRoyality}%</div>
                                      <div className="prce_grey">
                                        <p className="mb-0 price_1">Sales to </p>
                                        <p className="mb-0 price_2">the Creator</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="bid" role="tabpanel" aria-labelledby="bid-tab">
                  <div className="proposal_panel_overall">
                    <div className="inner_div_info">
                      {Bids && Bids.pending && Bids.pending.length > 0 && Bids.pending.map((curBid) => {
                        console.log("bid_list:",UserAccountAddr)
                      return (<div className="media follow_media info_media">
                        <img src={require("../assets/images/follower_1.png")} alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body">
                          <div>
                            {/* media_num */}
                            <p className="mt-0 ">{curBid.tokenBidAmt} {config.tokenSymbol} By {halfAddrShow(curBid.tokenBidAddress)} {tokenCounts_Detail.TotalQuantity > 0 &&
                             (<span>for {curBid.pending}/{curBid.NoOfToken} edition</span>)}</p>
                            <p className="mt-0 media_text mb-0">at {moment(curBid.timestamp).format('MMMM Do YYYY, h:mm a')}</p>
                          </div>
                          {UserAccountAddr && UserAccountAddr != curBid.tokenBidAddress && item && item.tokenowners_current && item.tokenowners_current.findIndex(e => e.tokenOwner == UserAccountAddr) > -1 &&
                          <div className="ml-2 ml-cus">
                            <Button className="create_btn mb-2" onClick={() => PlaceABidForwardRef.current.AcceptBid_Select(curBid)}>Accept</Button>
                          </div>
                          }
                          {UserAccountAddr && UserAccountAddr == curBid.tokenBidAddress &&
                            <Button className="create_btn mb-2" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(curBid)}>Cancel</Button>
                          }
                        </div>
                      </div>) }) }
                    </div>  
                  </div>
                </div>

                {
                (
                  (tokenCounts_Detail.TotalQuantity > MyTokenBalance)
                  ||
                  (Bids && Bids.highestBid && Bids.highestBid.tokenBidAmt)
                )?(
                <div className="card info_big_card mb-0">
                  <div className="card-body">
                    {(Bids.highestBid && Bids.highestBid.tokenBidAmt > 0) &&
                    <div className="media follow_media">
                      <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                      <div className="media-body flex_body">
                        <div>
                          <p className="mt-0 media_text_big_1">Highest bid by 
                          <span className="text_blk">
                            {(Bids.highestBid.bidBy && Bids.highestBid.bidBy.name)?Bids.highestBid.bidBy.name:''}
                          </span>
                          </p>
                          <p className="mt-0 mb-0 media_text_big_2"><span className="text_blk">{Bids.highestBid.tokenBidAmt} {config.tokenSymbol}</span></p>
                        </div>
                      </div>
                    </div>}
                    {
                    (
                    item
                    &&item.PutOnSaleType=="TimedAuction"&&item.clocktime != null
                    && item.endclocktime != null
                    && new Date(item.endclocktime) < Date.now() ) ?
                    (
                    <p className="mt-0 media_text_big_1 text-center">Auction Completed</p>
                    )
                    :
                    tokenCounts_Detail.TotalQuantity > MyTokenBalance?
                    <div className="mt-3 text-center">
                      {(item && item.PutOnSaleType == "TimedAuction"&& showlist == "true")?
                      (
            
                        <div className="countdown_panel info">
                      <div className="countdown_panel_div countdown_panel">
                              <Countdown
                        date={enddate}
                        autoStart={true}
                        onStart={() => startdate}
                        renderer={renderer}
                        ></Countdown>
                      <i class="fas fa-fire ml-2"></i>
                        </div>
                        </div>
                      ):('')}
                     
                      <br></br>
                      {
                      BuyOwnerDetailFirst && BuyOwnerDetailFirst.tokenOwner
                      ?
                        <Button className="btn_outline_red mb-2" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item, BuyOwnerDetailFirst)} >Buy Now</Button> 
                      :
                        MyTokenDetail && MyTokenDetail.balance > 0 && MyTokenDetail.tokenPrice > 0 ?
                          <Button className="btn_outline_red mb-2" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}>Change Price</Button>
                        :
                        MyTokenDetail && MyTokenDetail.balance > 0 && MyTokenDetail.tokenPrice == 0 &&
                          <Button className="btn_outline_red mb-2" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}>Put On Sale</Button>
                      }

                      {
                      Bids
                      && Bids.myBid
                      && !Bids.myBid.status
                      ?
                        <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click()}>
                          Bid now
                        </Button>
                      :
                        Bids
                        && Bids.myBid
                        && Bids.myBid.status
                        && Bids.myBid.status == 'pending' ?
                          <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click()}>
                            Edit a bid 
                          </Button>
                        :
                          Bids
                          && Bids.myBid
                          && Bids.myBid.status
                          && Bids.myBid.status == 'partiallyCompleted' &&
                          <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(Bids.myBid)}>
                            Cancel a bid
                          </Button>
                      }
                    </div>
                    :('')}
                    {/* {
                    (
                    item
                    && item.clocktime != null
                    && item.endclocktime != null
                    && new Date(item.endclocktime) < Date.now() ) ?
                    (<p className="mt-0 media_text_big_1 text-center">Auction Completed</p>)
                    :
                    tokenCounts_Detail.TotalQuantity > MyTokenBalance?
                    <div className="mt-3 text-center">
                      {(item && item.clocktime != null && item.endclocktime != null)?
                      (<badge className="badge badge-secondary">
                      <Countdown date={new Date(item.endclocktime)}
                        autoStart={true}
                        onStart={() => new Date(item.clocktime)}
                        // onStop={new Date(item.endclocktime)}
                        renderer={renderer}
                      >
                      </Countdown>
                      <i class="fas fa-fire ml-2"></i>
                      </badge>):('')}
                      <br></br>
                      {
                      BuyOwnerDetailFirst && BuyOwnerDetailFirst.tokenOwner
                      ?
                        <Button className="btn_outline_red mb-2" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item, BuyOwnerDetailFirst)} >Buy Now</Button> 
                      :
                        MyTokenDetail && MyTokenDetail.balance > 0 && MyTokenDetail.tokenPrice > 0 ?
                          <Button className="btn_outline_red mb-2" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}>Change Price</Button>
                        :
                        MyTokenDetail && MyTokenDetail.balance > 0 && MyTokenDetail.tokenPrice == 0 &&
                          <Button className="btn_outline_red mb-2" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}>Put On Sale</Button>
                      }
                      {
                      Bids
                      && Bids.myBid
                      && !Bids.myBid.status && item.tokenOwner != UserAccountAddr 
                      ?
                        <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click()}>
                          Bid now
                        </Button>
                      :
                        Bids
                        && Bids.myBid
                        && Bids.myBid.status
                        && Bids.myBid.status == 'pending' ?
                          <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.EditABid_Click()}>
                            Edit a bid 
                          </Button>
                        :
                          Bids
                          && Bids.myBid
                          && Bids.myBid.status
                          && Bids.myBid.status == 'partiallyCompleted' &&
                          <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(Bids.myBid)}>
                            Cancel a bid
                          </Button>
                      }
                    </div>
                    :('')} */}

                    {tokenCounts_Detail.TotalQuantity > MyTokenBalance?
                    <p className="mt-0 media_text_big_1 text-center">Service fee {config.fee/config.decimalvalues}%</p>
                    :('')}
                  </div>
                </div>):(
                   showlist=='false'&&auction=="true" &&
                   <div className="card info_big_card mb-0">
                  <div className="card-body">
                    <div className="mt-3 text-center">
                      <div className="countdown_panel ">
                        <div className="countdown_panel_div countdown_panel ended">
                         <span> {auctionTxt}</span>
                        </div>
                      </div>
                  </div>
                  </div>
                  </div>
                 
                )}


                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </main>

      <WalletModal />

      {/* buy Modal */}
      <div class="modal fade primary_modal" id="buy_modal" tabindex="-1" role="dialog" aria-labelledby="buy_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="buy_modalLabel">Checkout</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row mx-0 bor_bot_modal">
                <div className="col-12 col-md-12 p-0">
                  <p className="buy_title_sm">Seller</p>
                  <p className="buy_title_md">Uniaqua</p>
                </div>
                <div className="col-12 col-md-12 p-0">
                  <p className="buy_title_sm">Buyer</p>
                  <p className="buy_title_md short_address">0x75Cf28f29266A413eC878CE5C4a1c9682C819586</p>
                </div>
              </div>
              <div className="bor_bot_modal mb-3">
                <p className="buy_title_md px-4 py-3 text-center mb-0">1.01 ETH</p>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Your balance</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">10.25 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">0.012 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">You will pay</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">1.112 ETH</p>
                </div>
              </div>
              <form className="p-0">
                <div className="text-center">
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_modal">Proceed to payment</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end buy modal */}

      {/* proceed Modal */}
      <div class="modal fade primary_modal" id="proceed_modal" tabindex="-1" role="dialog" aria-labelledby="proceed_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="proceed_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <p className="mt-0 purchase_text text-center">Purchase</p>
                <p className="mt-0 purchase_desc text-center">Send transaction with your wallet</p>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Inprogress</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed modal */}

      {/* place_bid Modal */}
      <div class="modal fade primary_modal" id="place_bid_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="place_bid_modalLabel">Place a bid</h5>
              <p className="text-center place_bit_desc">You are about to place a bid for</p>
              <p className="place_bit_desc_2"><span className="text_red mr-2">Uniaqua</span>by<span className="text_red ml-2">NFTbuyer</span></p>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="bid_form">
                <label for="bid">Your bid</label>
                <div class="input-group mb-3 input_grp_style_1">
                  <input type="text" id="bid" class="form-control" placeholder="Enter your display name" aria-label="bid" aria-describedby="basic-addon2" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">ETH</span>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">10.25 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">0.012 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>
                <div className="text-center">
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal">Place a bid</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}


      {/* place_bid multiple */}
      <div class="modal fade primary_modal" id="place_bid_multiple_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_multiple_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="place_bid_multiple_modalLabel">Place a bid</h5>
              <p className="text-center place_bit_desc">You are about to place a bid for</p>
              <p className="place_bit_desc_2"><span className="text_red mr-2">Uniaqua</span>by<span className="text_red ml-2">NFTbuyer</span></p>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="px-4 bid_form">
                <label for="bid">Your bid</label>
                <div class="input-group mb-3 input_grp_style_1">
                  <input type="text" id="bid" class="form-control" placeholder="Enter your display name" aria-label="bid" aria-describedby="basic-addon2" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">ETH</span>
                  </div>
                </div>

                <label for="qty">Enter quantity <span className="label_muted pl-2">(30 available)</span></label>
                <div class="mb-3 input_grp_style_1">
                  <input type="text" id="qty" class="form-control" placeholder="1" />

                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">10.25 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">0.012 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>
                <div className="text-center">
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal">Place a bid</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal multiple */}

      {/* proceed_bid Modal */}
      <div class="modal fade primary_modal" id="proceed_bid_modal" tabindex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Done</Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Signature</p>
                    <p className="mt-0 approve_desc">Create a signatute to place a bid</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button className="btn_outline_red btn-block" disabled>Start</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      <div class="modal fade primary_modal" id="accept_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="accept_modalLabel">Accept bid</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="img_accept text-center">
                <img src={require("../assets/images/img_info.png")} alt="Collections" className="img-fluid" />
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">Uniaqua</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold pl-2 short_address m-auto d-block">0x75Cf28f29266A413eC878CE5C4a1c9682C819586</span>
              </p>
              <p className="info_title text-center">0 ETH for 1 edition(s)</p>


              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">0.012 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">1.112 ETH</p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Accept bid</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}

    </div>
  );
}

Info.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Info);
